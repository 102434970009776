<template>
  <div>
    <div class="free_week">
      <div class="free_week__filter"></div>

      <b-container>
        <b-row class="full_height" align-h="center" align-v="end">
          <b-col cols="12" md="6">
            <h1 class="subscription_easy_title">
              Подписка <span class="accent">БК</span> Состояние
            </h1>

            <h2 class="subscription_easy_justi_text">
              Множество уровней с отборным контентом и вопросами, проходя
              которые, вы будете держать себя в фокусе на своих истинных
              желаниях и целях.
            </h2>

            <b-row align-h="center">
              <b-col cols="12" md="7">
                <button @click="turnOnAutoSubscribe()" class="easy_button">
                  Попробовать 7 дней за 1₽
                </button>

                <p class="ghost_subtext">Отменить можно в любой момент</p>
              </b-col>
            </b-row>

            <b-row align-h="center">
              <b-col cols="11" md="9">
                <p class="ghost_description">
                  {{
                    subscriptions
                      ? `После - ${subscriptions[0].cost} в месяц.`
                      : ""
                  }}
                  Подписку можно отменить в любое время на странице профиля.
                </p>

                <p class="ghost_description">
                  Мы не получаем и не храним данные банковских карт клиентов.
                  Платежные операции проходят через интернет-сервис
                  <a
                    href="https://yookassa.ru/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >ЮKassa</a
                  >, что гарантирует безопасность платежей.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="6">
          <p class="subscription_easy_text">
            <b>МЫ ТО, ЧТО НАС ОКРУЖАЕТ.</b> <br />"Состояние" и БКteams в целом
            - это еще один способ окружить себя полезным контентом и людьми.
            <br />
            <br />
            Мы создали для Вас информационное поле, находясь в котором, Вы в
            скором времени захотите становиться лучше и развиваться.
          </p>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="10" md="4">
          <img src="../../assets/img/mac_and_iphone.png" alt="" />
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="10" md="3">
          <p class="ghost_description">
            Доступно на любом устройстве и в любом месте где есть интернет
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "FreeWeek",
  computed: {
    subscriptions() {
      return this.$store.state.bkonline.subscriptionTypes;
    },
    subscription() {
      return this.$store.state.user.subscription;
    },
  },
  methods: {
    ...mapActions(["getSubscriptionTypes"]),
    async turnOnAutoSubscribe() {
      try {
        this.$store.commit("SET_LOADING", true);

        const payURL = await axios
          .post("turn_on_auto_subscriptions", {
            typePay: "bank_card",
            returnUrl: "tasks",
          })
          .then((res) => {
            return res.data.confirmation_url;
          });

        document.location = payURL;

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);

        console.error(e);
      }
    },
  },
  async mounted() {
    try {
      this.$store.commit("SET_LOADING", true);

      if (localStorage.getItem("token")) {
        await this.$store.dispatch("getUser");

        if (this.subscription && this.subscription.expiration_date) {
          this.$router.push({ name: "Subscribe" });
        }

        await this.getSubscriptionTypes();
      }

      this.$store.commit("SET_LOADING", false);
    } catch (error) {
      this.$store.commit("SET_LOADING", false);
    }
  },
};
</script>

<style lang="scss">
.full_height {
  height: 100vh;
  padding-bottom: 4vh;
  margin-bottom: 50px;
}

.free_week {
  position: relative;
  padding-bottom: 30px;

  background-position: 40% 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/ali-kazal-1OkIXsRu4Oo-unsplash.jpg");
}

.free_week__filter {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: linear-gradient(
    0deg,
    rgba(20, 20, 20, 1) 10%,
    rgba(31, 31, 31, 0.4) 100%
  );
}

.easy_button {
  display: block;
  width: 100%;
  padding: 10px 50px;
  margin: 0 auto;
  border-radius: $border;

  background-color: rgba($color: $accent, $alpha: 0.9);

  font-weight: 600;
  font-size: 14px;
}
.subscription_easy_title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: rgba($color: $white, $alpha: 0.9);
}
.subscription_easy_justi_text {
  text-align: justify;
  font-size: 15px;
  font-weight: 500;
  color: rgba($color: $white, $alpha: 0.8);
  padding-bottom: 30px;
}
.subscription_easy_text {
  font-size: 20px;
  font-weight: 500;
  color: rgba($color: rgb(92, 101, 120), $alpha: 0.7);
  padding-top: 15vh;
  padding-bottom: 20vh;
}
.ghost_subtext {
  text-align: center;
  font-size: 12px;
  margin-top: 3px;

  color: rgba($color: $light_gray__dark, $alpha: 0.7);
}
.ghost_description {
  text-align: center;
  font-size: 13px;
  font-weight: 400;

  color: rgba($color: $light_gray__medium, $alpha: 0.6);
}
</style>
